import * as React from "react"
import { useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Container } from "../components/utils"

function whenAvailable(name, callback) {
  if(typeof window === "undefined") return;

  var interval = 10; // ms
  window.setTimeout(function() {
    if (window[name]) {
      callback(window[name]);
    } else {
      whenAvailable(name, callback);
    }
  }, interval)
}

const CareersPage = () => {

  useEffect(() => {
    whenAvailable('whr', () => {
      if(typeof window !== 'undefined'){
        window.whr(document).ready(function(){
          window.whr_embed(516871, { detail: 'descriptions', base: 'departments', zoom: 'country' });
        })
      }
    })
  }, [])

  return (
    <Layout
      title="Work with us"
      description='Join our team of professionals and kick start your career in the Forex industry.'
    >
      <Helmet>
        <script
          key={`workable`}
          src={`/workable/embed.js`}
        />
      </Helmet>
      <Container>
        <div id="whr_embed_hook" />
      </Container>
    </Layout>
  )
}

export default CareersPage
